<script>
import { defineComponent } from 'vue'

export default defineComponent({
  mixins: [],
  data() {
    return {}
  },
  async mounted() {
  },
  beforeUnmount() {},
  methods: {}
})
</script>

<template>
  <RouterView />
</template>

<style scoped lang="scss"></style>