if(import.meta.env.VITE_MODE === 'test'){
  var _hmt = _hmt || [];
  (function() {
    let hm = document.createElement("script");
    hm.src = "https://hm.baidu.com/hm.js?4ad8aba6355b865b132a85c552230f8e";
    let s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(hm, s);
  })();
}
if(import.meta.env.VITE_MODE === 'production'){
  var _hmt = _hmt || [];
  (function() {
    let hm = document.createElement("script");
    hm.src = "https://hm.baidu.com/hm.js?9edb84068ee835d2b1491f904910fac2";
    let s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(hm, s);
  })();
}
