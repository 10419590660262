import { usePermissionStore } from "@/store/permission";

let permissionStore;
const checkKey = (key,binding) => {
  let keyList = key.split(',')
  if (!permissionStore) {
    permissionStore = usePermissionStore();
  }
  let list
  if(binding?.instance?.$parent?.$attrs?.formPageLayout){
    list = permissionStore.allPermissions
  }else{
    list = permissionStore.activePermissions
  }
  return list.some(item=>{
    return keyList.includes(item)
  })
};
/**
 * v-btn-key="'project.add'"
 * v-btn-key="'project.add,project.edit'"
 * 该标签上不可使用v-if，可使用v-show
 * 多个采用逗号分隔
 * 父级标签为tamplate标签时，不可使用v-if
 * 如需使用外层嵌套div
 */
export default {
  name: 'btn-key',
  mounted(el, binding) {
    let key = binding.value
    if (key) {
      let checkShow = checkKey(key,binding)
      if (!checkShow) {
        el.remove()
      }
    } else {
      throw new Error('缺少唯一指令')
    }
  }
}
