import axios from './axios.config'
// 获取配置列表
export const listConfig = () => axios.get('/jaxrs/system_config/list')

// 保存消息配置
export const saveMessageNotification = (data) => axios.post(`/jaxrs/system_config/save_message_notification`, data)
// 保存合同模版
export const saveContractTemplate = (data) => axios.post(`/jaxrs/system_config/save_contract_template`, data)
// 获取系统配置
export const getSystemInfo = () => axios.get('/jaxrs/system_config/get_system_info')
// 保存系统配置
export const saveSystemInfo = (data) => axios.post('/jaxrs/system_config/save_system_info',data)
// 保存邮件信息模版
export const saveEmailMessageTemplate = (data) => axios.post('/jaxrs/system_config/save_email_message_template',data)
// 发送邮件
export const sendEmailMessage = (data) => axios.post('/jaxrs/email_message/send',data)
