import { defineStore } from "pinia";

export const useMapStore = defineStore({
  id: "map",
  state: () => {
    return {
      // appKey替换后，会影响自定义主题
      // appKey: "WXEBZ-PHXCS-ATYO3-6FB44-ZWCPO-OGBPS", //LLY
      appKey: "5JABZ-FTKRB-OAMUS-NCIR2-UUEPK-ZXFSJ", //ZZP
      latitude: 31.345877,
      longitude: 121.482041
      // latitude: 35.406059,
      // longitude: 119.557941
    };
  },
  actions: {
    async loadMapScript() {
      return new Promise((resolve, reject) => {
        if (window.TMap) {
          resolve(window.TMap);
        } else {
          window.mapInit = () => {
            resolve(window.TMap); //注意这里
          };
          let script = document.createElement("script");
          script.type = "text/javascript";
          script.src = `https://map.qq.com/api/gljs?v=1.exp&callback=mapInit&key=${this.appKey}&libraries=geometry,visualization,service`;
          script.onerror = reject;
          document.head.appendChild(script);
        }
      });
    }
  }
});

