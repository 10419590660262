<script>
import { defineComponent } from 'vue'

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Instructions',
  emits: ['update:modelValue','agree'],
  props: {
    modelValue: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    show: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      }
    }
  },
  methods:{
    handleAgreeClick(){
      this.show = false
      this.$emit('agree')
    }
  }
})
</script>

<template>
  <el-dialog v-model="show" width="80%" title="使用协议书" modal-class="instructions-modal">
    <div class="instructions-wrapper" style="font-family: Microsoft Sans Serif" v-html="loginStore.loginConfig.usageAgreement">
    </div>
    <div class="instructions-wrapper" style="font-family: Microsoft Sans Serif" v-if="false">
      <h1 style="font-size: 18px; margin: 20px 0 10px; font-weight: bold; text-align: center">
        员工系统平台使用告知
      </h1>
      <p style="font-size: 14px; line-height: 1.6; text-indent: 2em">
        源安管理平台是上海源安安全科技有限公司（以下简称源安科技）用于管理源安科技及关联公司的员工和业务开发的软件平台。所有使用本系统的人员请认真阅读以下内容，并遵守公司及系统平台的管理规定。
      </p>
      <p style="font-size: 14px; line-height: 1.6; text-indent: 2em">
        1.所有使用者账号及登录密码经审批后分配到个人。限本人使用并负责管理，不得泄漏、外借账号密码及使用非本人账号登陆使用本系统。泄漏、外借系统账号密码所产生的法律责任由使用者承担。
      </p>
      <p style="font-size: 14px; line-height: 1.6; text-indent: 2em">
        2.使用者在本系统的操作都是本人真实意愿的表达，操作后的记录、文件、影像资料可作为公司管理、员工处罚、法律诉讼的依据。
      </p>
      <p style="font-size: 14px; line-height: 1.6; text-indent: 2em">
        3.系统平台上的文件、资料所有权归源安科技所有，仅可在授权范围内用于公司业务，非授权私自调阅、下载、截屏、转载、打印及类似行为违反公司规定使行为，视情节给予公司内部处罚直至追究法律责任。
      </p>
      <p style="font-size: 14px; line-height: 1.6; text-indent: 2em">
        4.公司通过系统平台所发送的公告、通知及文件视同送达使用者本人，请及时阅读、回复。业务流程操作超时限的是工作效率低下的表现，超时记录会作为处罚依据。
      </p>
      <p style="font-size: 14px; line-height: 1.6; text-indent: 2em">
        5.任何试图破解本系统平台、上传带有病毒程序的文件都是违法行为，将报送公安机关处理。
      </p>
      <p style="font-size: 14px; line-height: 1.6; text-indent: 2em">
        6.本系统平台仅用于公司内部管理与业务管理使用，私自传播、保存与之无关的文件资料，查实后将受到处罚。违反国家、地方法律法规的使用者承担相应的法律责任。
      </p>
      <p style="font-size: 14px; line-height: 1.6; text-indent: 2em">
        7.使用本系统平台需开通移动端的定位、摄像头、文件管理等权限，收集的信息仅用于公司内部管理，请勿在工作时间关闭。
      </p>
      <p style="font-size: 14px; line-height: 1.6; text-indent: 2em">
        8.不得利用本系统发布或传输任何骚扰性的、中伤他人的、辱骂性的、恐吓性的、庸俗淫秽的或其他任何非法的、可能产生不良后果的信息资料，不得发布任何包含种族、性别、宗教有歧视性和攻击性的信息内容。
      </p>
      <p style="font-size: 14px; line-height: 1.6; text-indent: 2em">
        9.
        本系统同尊重并保护所有用户的个人隐私权，未经用户许可或根据相关法律、法规的强制性规定，本系统不主动将用户个人信息泄露给任何第三方。
      </p>
      <p style="font-size: 14px; line-height: 1.6; text-indent: 2em">二、免责声明</p>
      <p style="font-size: 14px; line-height: 1.6; text-indent: 2em">
        1.
        本公司将按照国家法律法规要求，采取合理的技术措施保护用户的个人数据，在现有技术条件下，无法完全保证数据的安全性、完整性和私密性。用户在使用本公司的产品和服务时，应自行承担一定的风险，并理解和接受这一风险。
      </p>
      <p style="font-size: 14px; line-height: 1.6; text-indent: 2em">
        2. 在法律法规允许的范围内，本公司不对以下情况导致的用户权益受损承担责任：
      </p>
      <p style="font-size: 14px; line-height: 1.6; text-indent: 2em">a. 用户自行泄露个人数据；</p>
      <p style="font-size: 14px; line-height: 1.6; text-indent: 2em">
        b. 用户将个人账户密码等信息泄露或告知他人；
      </p>
      <p style="font-size: 14px; line-height: 1.6; text-indent: 2em">
        c.
        因黑客攻击、计算机病毒侵入及其他不可抗力因素导致的用户个人数据泄露、丢失、被盗用或被篡改等后果；
      </p>
      <p style="font-size: 14px; line-height: 1.6; text-indent: 2em">
        d. 由于政府机关依法对用户个人数据的查封、冻结或扣押而引起的用户权益受损
      </p>
      <p></p>
    </div>
    <template #footer>
      <el-button type="primary" @click="handleAgreeClick">同意</el-button>
    </template>
  </el-dialog>
</template>

<style scoped lang="scss">
.instructions-wrapper{
  height: 500px;
  overflow: auto;
}
</style>