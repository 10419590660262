import axios from './axios.config'
// 获取字典（批量）
export const openApiGetDict = (data)=>axios.post(`/jaxrs/open_api/dict/${data.types}`)
// 上传文件
export const openApiUpload = (data)=>axios.post('/jaxrs/open_api/upload',data,{
  headers:{
    'Content-Type': 'multipart/form-data;'
  }
})
// 保存入职申请
export const openApiSubmitJobApplication = (data)=>axios.post('/jaxrs/open_api/job_application',data)
// 保存入职申请
export const openApiGetJobApplicationByIdCard = (data)=>axios.get(`/jaxrs/open_api/job_application/id_card/${data.idCard}`)
// 保存入职申请
export const openApiGetSkillCertificateTypeList = ()=>axios.get(`/jaxrs/open_api/list_skill_certificate_type`)
