<template>
  <div class="full-wrapper">
    <PageHeader></PageHeader>
    <div class="full-body">
      <router-view />
    </div>
  </div>
</template>

<script>

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'HomeLayout',
  data() {
    return {}
  },
  computed: {

  },
  async mounted() {},
  methods: {
  }
}
</script>

<style scoped lang="scss">
.full-wrapper{
  @include max-size;
  @include flex-column;
}
.full-body {
  @include flex-row;
  flex: 1;
  height: 100%;
  overflow: hidden;
  transform: translate(0, 0);
}
</style>