import { defineStore } from 'pinia'
import { getConsumeList, getMyCompletedTask, getMyTaskList, getOnAllocationPage } from "@/axios";
import { ElNotification } from 'element-plus'
import { h } from 'vue'
import _ from 'lodash'
import dayjs from "dayjs";
import { useComponentStore } from "@/store/component";
import EventBus from "@/utils/eventBus";
let componentStore
export const useMessageStore = defineStore({
  id: 'message',
  state: () => {
    return {
      messageList: [],
      task:{
        myTask:{
          total:0
        },
        notifyTask:{
          total:0
        }
      }
    }
  },
  getters: {
    messageLength() {
      return this.messageList ? this.messageList.length : 0
    }
  },
  actions: {
    async initMessageList() {
      await getConsumeList(999).then((res) => {
        res.data.forEach((item) => {
          item.body = JSON.parse(item.body)
        })
        // this.messageList = res.data
      })
    },
    addMessage(data) {
      data.time = dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss')
      let notify
      if (data.body && _.isObject(data.body)) {
        if(data.body.first){
          return
        }
        notify = ElNotification({
          title: '待办提醒',
          message: h(
            'div',
            {
              style: 'text-align: left;word-break: break-all;'
            },
            [
              h('div', {}, data.title),
              h('div', {}, [
                h('span', { style: 'font-weight: blob;' }, `应用：`),
                h('span', { style: 'color: red;' }, data.body.applicationName)
              ]),
              h('div', {}, [
                h('span', { style: 'font-weight: blob;' }, `流程：`),
                h('span', { style: 'color: red;' }, data.body.processName)
              ]),
              h('div', {}, data.time)
            ]
          ),
          duration: 1000 * 5,
          onClick() {
            if(!componentStore){
              componentStore = useComponentStore()
            }
            componentStore.showProcessDetail(data.body)
            notify.close()
          }
        })
      } else {
        notify = ElNotification({
          title: '消息提醒',
          message: h(
            'div',
            {
              style: 'text-align: left;word-break: break-all;'
            },
            [h('div', {}, data.title), h('div', {}, data.time)]
          ),
          duration: 1000 * 5
        })
      }
      this.messageList.unshift(data)
      this.getTaskPageCount()
    },
    deleteMessage(deleteIndex) {
      this.messageList = this.messageList.filter((item, index) => {
        return deleteIndex !== index
      })
    },
    clearMessage() {
      this.messageList = []
    },
    async getTaskPageCount(){
      EventBus.emit('messageChange')
      await Promise.all([this.getMyTaskPage("myTask"),this.getMyTaskPage("notifyTask")])
    },
    async getMyTaskPage(type) {
      let params = {
        pageSize: 1,
        pageNumber: 1,
        params: {}
      }
      let pageMethod
      if (type === 'myTask') {
        pageMethod = getMyTaskList
      }
      if (type === 'completedTask') {
        pageMethod = getMyCompletedTask
      }
      if (type === 'notifyTask') {
        pageMethod = getOnAllocationPage
      }
      await pageMethod(params)
        .then((res) => {
          if (res) {
            this.task[type].total = res.count
            // this.total = res.count
          }
        })
        .catch(() => {})
        .finally(() => {
        })
    },
  }
})
