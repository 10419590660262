import { defineStore } from 'pinia'
import { getLoginUserInfo } from "@/axios";

export const useUserStore = defineStore({
  id: 'user',
  persist: true,
  state: () => {
    return {
      userInfo: {}
    }
  },
  actions: {
    async initUserInfo() {
      await getLoginUserInfo().then((res)=>{
        if (res) {
          this.userInfo = res.data
        }
      }).catch(()=>{
      })
    }
  }
})
