import axios from 'axios'
import { ElMessage } from 'element-plus'
import Cookies from 'js-cookie'
axios.defaults.timeout = 1000 * 60 * 5
// axios.defaults.withCredentials = false
const instance = axios.create({
  baseURL: import.meta.env.VITE_AXIOSBASEURL
})

instance.interceptors.request.use(
  (config) => {
    let token = Cookies.get('x-token')
    // config.headers['x-token'] = layout.session.token
    config.headers['x-token'] = token
    return config
  },
  (error) => {
    return Promise.error(error)
  }
)

// 响应拦截器
instance.interceptors.response.use(
  async (response) => {
    if (response.status === 200) {
      if (response.headers['content-type'] && response.headers['content-type'].includes('application/json')) {
        if (response.request.responseType === 'blob') {
          response.data = JSON.parse(await response.data.text())
        }
        if (response.data.type === 'success') {
          return response.data
        } else {
          if(response.data.data){
            await Promise.reject(response.data)
          }else{
            ElMessage.error(response.data.message)
            await Promise.reject(response.data)
          }
        }
      } else {
        const contentDisposition = response.headers['content-disposition']
        if (contentDisposition) {
          const filenameRegex = /filename=?([^"]+)?/;
          const match = contentDisposition.match(filenameRegex);
          let filename = '';
          if (match && match[1]) {
            filename = decodeURIComponent(match[1]);
          }
          response.data.filename = filename
        }
        return response.data
      }
    } else {
      await Promise.reject(response.data)
    }
  },
  async (error) => {
    if (error && error?.response?.data) {
      let errorData = error.response.data
      if(errorData.message){
        if(!new RegExp('^用户:.*?权限不足.$').test(errorData.message)){
          ElMessage.error(errorData.message)
        }
      }
      await Promise.reject(errorData)
    }
  }
)

export default instance
// // get请求
// export function get(url, data, config) {
//     return axios.get(url, data, config)
// }
//
// // post请求
// export function post(url, data, config) {
//     return axios.post(url, data, config)
// }
//
// // put请求
// export function put(url, data, config) {
//     return axios.put(url, data, config)
// }
//
// // delete 请求
// export function del(url, data, config) {
//     return axios.delete(url, data, config)
// }
//
// // upload 请求
// export function uploader(url, file) {
//     let params = new FormData()
//     params.append('file', file)
//     return axios.post(url, params)
// }
