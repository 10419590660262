import { defineStore } from "pinia";
import { getPersonCustom, updatePersonCustom } from "@/axios";
import { layout } from "@o2oa/component";

export const useSearchParamsStore = defineStore({
  id: "searchParams",
  persist: {
    enabled: true
  },
  state: () => {
    return {
      history: []
    };
  },
  getters: {
    newSearchParamsId: (state) => {
      if (state.history.length === 0) {
        return 1;
      } else {
        return state.history[state.history.length - 1].id + 1;
      }
    }
  },
  actions: {
    async initSearchParamsList() {
      await getPersonCustom({
        key: 'searchParams'
      })
        .then((res) => {
          let list = []
          if (res?.data) {
            list = JSON.parse(res.data)
          }
          this.history = list
        })
        .catch(() => {
        })
    },
    addSearchParamsHistory(item) {
      if (item.isFirst) {
        let { tableId } = item;
        let history = this.getSearchParamsHistoryByTableId(tableId);
        history.forEach(item => {
          item.isFirst = false;
        });
      }
      this.history.push({
        ...item,
        id: this.newSearchParamsId
      });
      this.updateSearchParamsList()
    },
    getSearchParamsHistoryByTableId(tableId) {
      if (!tableId) {
        return [];
      }
      let history = this.history.filter(item => item.tableId === tableId);
      history = history.sort(item => item.isFirst ? -1 : 1);
      return history;
    },
    deleteSearchParamsHistory(item) {
      this.history = this.history.filter(hItem => hItem.id !== item.id);
      this.updateSearchParamsList()
    },
    clearSearchParamsHistory() {
      this.history = [];
      this.updateSearchParamsList()
    },
    async updateSearchParamsList() {
      await updatePersonCustom({
        name: layout.user.name,
        key: 'searchParams',
        params: this.history
      })
        .then(() => {
          this.initSearchParamsList()
        })
        .catch(()=>{})
    },
  }
});

