import { defineStore } from 'pinia'
import { getPersonCustom, updatePersonCustom } from '@/axios'
import { layout } from '@o2oa/component'
import { usePermissionStore } from "@/store/permission";
import _ from 'lodash'
let permissionStore
export const useCollectStore = defineStore({
  id: 'collect',
  state: () => {
    return {
      totalCollectList: [],
      collectList: []
    }
  },
  getters:{
    permissionCollectList(){
      if(!permissionStore){
        permissionStore = usePermissionStore()
      }
      let list = _.cloneDeep(this.totalCollectList).map((item=>{
        let module = permissionStore.moduleList.find(mItem=>mItem.module === item.module)
        if(module){
          item.moduleName = module.name
        }
        let menuItem = permissionStore.allPermissionsMenuList.find((pItem)=>{
          return `/${pItem.path}` === item.router
        })
        if(menuItem){
          item.icon = menuItem.icon
        }
        return item
      })).filter(item=>item.moduleName)
      return list
    }
  },
  actions: {
    async initCollectList() {
      if(!permissionStore){
        permissionStore = usePermissionStore()
      }
      await getPersonCustom({
        key: 'collect'
      })
        .then((res) => {
          let list = []
          if (res?.data) {
            list = JSON.parse(res.data)
          }
          this.totalCollectList = list
          this.collectList = list.filter((item) => item.module === permissionStore.module)
        })
        .catch(()=>{})
    },
    async changeCollect(routeParams) {
      if(!routeParams.module){
        routeParams.module = permissionStore.module
      }
      if (this.totalCollectList.find((item) => {
        return item.router === routeParams.router && item.module === routeParams.module
      })) {
        this.totalCollectList = this.totalCollectList.filter((item) => {
          return !(item.router === routeParams.router && item.module === routeParams.module)
        })
      } else {
        this.totalCollectList = [...this.totalCollectList, routeParams]
      }
      updatePersonCustom({
        name: layout.user.name,
        key: 'collect',
        params: this.totalCollectList
      })
        .then(() => {
          this.initCollectList()
        })
        .catch(()=>{})
    }
  }
})
