let getModules = () => {
  return import.meta.glob('./*.js',{ eager: true })
}

const modulesFiles = getModules();

const directives = {}

for (const key of Object.keys(modulesFiles)) {
  let file = modulesFiles[key]
  for (let key in file) {
    const moduleName = file[key].name
    directives[moduleName] = file[key]
  }
}

export default directives

