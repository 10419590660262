import { defineStore } from 'pinia'
import { getApplicationListComplex } from "@/axios";

export const useAppStore = defineStore({
  id: 'app',
  persist: true,
  state: () => {
    return {
      applicationListSummary: []
    }
  },
  getters: {
    processList: (state) => {
      let list = []
      let appList = state.applicationListSummary.filter(item=>item.applicationCategory === '应用')
      appList.forEach(item=>{
        item.processList.forEach(pItem=>{
          list.push({
            ...pItem,
            applicationName:item.name
          })
        })
      })
      return list
    }
  },
  actions: {
    async initAppList(){
      await getApplicationListComplex().then(res=>{
        this.applicationListSummary = res.data
      })
    },
    getApplicationById(id){
      let app = this.applicationListSummary.find(item=>item.id === id)
      return app || {}
    },
    getApplicationIconById(id){
      let app = this.getApplicationById(id)
      if(app.icon){
        return `data:image/png;base64,${app.icon}`
      }
      return ''
    }
  }
})
