// initial state
import { defineStore } from 'pinia'
export const useEnumItemStore = defineStore({
    id:"enumItem",
    state:() => {
        return {
            user: {
                sexEnum: [{key: 1, value: '男'}, {key: 2, value: '女'}],
                statusEnum: [{key: 1, value: '启用'}, {key: 2, value: '禁用'}],
                levelEnum: [{key: 1, value: '初级'}, {key: 2, value: '中级'}, {key: 3, value: '高级'}],
                roleEnum: [{key: 1, value: '学生'}, {key: 2, value: '教师'}, {key: 3, value: '管理员'}],
                statusTag: [{key: 1, value: 'success'}, {key: 2, value: 'danger'}],
                statusBtn: [{key: 1, value: '禁用'}, {key: 2, value: '启用'}]
            },
            exam: {
                periodExam: {
                    periodTypeEnum: [{key: 1, value: "每日"}, {key: 2, value: "每周"}, {key: 3, value: "每月"}],
                    hasAnalyseEnum: [{key: 0, value: '否'}, {key: 1, value: '是'}]
                },
                freeExam: {
                    modelEnum: [{key: 1, value: '自由练习'}, {key: 2, value: '挑战答题'}, {key: 3, value: '错题练习'}, {
                        key: 4,
                        value: '对战模式'
                    }],
                    hasAnalyseEnum: [{key: false, value: '否'}, {key: true, value: '是'}]
                },
                examPaper: {
                    paperTypeEnum: [{key: 1, value: '固定试卷'}, {key: 4, value: '时段试卷'}, {key: 6, value: '任务试卷'}, {
                        key: 7,
                        value: '机选试卷'
                    }, {key: 8, value: "周期试卷"}]
                },
                question: {
                    typeEnum: [{key: '1', value: '单选题'}, {key: '2', value: '多选题'}, {key: '3', value: '判断题'},
                        {
                            key: '4',
                            value: '填空题'
                        }, {key: '5', value: '简答题'}
                    ],
                    editUrlEnum: [{key: '1', value: 'edit/singleChoice', name: '单选题'},
                        {key: '2', value: 'edit/multipleChoice', name: '多选题'},
                        {key: '3', value: 'edit/trueFalse', name: '判断题'},
                        {key: '4', value: 'edit/gapFilling', name: '填空题'},
                        {key: '5', value: 'edit/shortAnswer', name: '简答题'}
                    ]
                }
            },
            studyPlan: {
                typeEnum: [
                    {value: '固定时间', key: 1}
                ]
            }
        }
    },
    getters:{
        enumFormat: (state) => (arrary, key) => {
            return format(arrary, key)
        }
    },
    actions: {
    }
})

const format = function (array, key) {
    for (let item of array) {
        if (item.key === key) {
            return item.value
        }
    }
    return null
}
