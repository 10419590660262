import axios from './axios.config'
// 获取工单图片配置显示字段
export const getWorkOrderImageConfigFields = (tableId='workOrderImageConfig') => axios.get(`/jaxrs/work_order_image_config/fields?tableId=${tableId}`)
// 获取工单图片配置查询条件
export const getWorkOrderImageConfigConditions = () => axios.get('/jaxrs/work_order_image_config/conditions')
// 获取工单图片配置分页数据
export const getWorkOrderImageConfigPage = (data) => axios.post('/jaxrs/work_order_image_config/page',data)
// 获取工单图片配置详情
export const getWorkOrderImageConfigDetail = (data) => axios.get(`/jaxrs/work_order_image_config/${data.id}`)
// 修改工单图片配置
export const editWorkOrderImageConfig = (data) => axios.put(`/jaxrs/work_order_image_config`,data)
// 添加工单图片配置
export const addWorkOrderImageConfig = (data) => axios.post(`/jaxrs/work_order_image_config`,data)
// 删除工单图片配置
export const deleteWorkOrderImageConfig = (data) => axios.delete(`/jaxrs/work_order_image_config/${data.id}`)


