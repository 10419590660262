import { defineStore } from 'pinia'
import { getWorkOrderImageConfigPage } from "@/axios/workOrderImageConfig"
export const useWorkOrderImageConfigStore = defineStore({
  id: 'workOrderImageConfig',
  state: () => {
    return {
      workOrderImageConfigList:[]
    }
  },
  actions: {
    getWorkOrderImageConfig(bizType,workOrderSource) {
      let workOrderImageConfig = this.workOrderImageConfigList.find(item=>{
        return item.bizType === bizType && item.workOrderSource === workOrderSource
      })
      if(workOrderImageConfig){
        return workOrderImageConfig.items
      }
      return []
    },
    async initWorkOrderImageConfigList(){
      await getWorkOrderImageConfigPage({pageNumber:1,pageSize:99999}).then(res=>{
        if(res){
          this.workOrderImageConfigList = res.data
        }
      })
    }
  }
})

