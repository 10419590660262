import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

let store = createPinia()
store.use(piniaPluginPersistedstate)

let getModules = () => {
  return import.meta.glob('./*.js', { eager: true })
}

const modulesFiles = getModules()
let stores = []
for (const key of Object.keys(modulesFiles)) {
  let file = modulesFiles[key]
  for (let key in file) {
    stores.push(file[key])
  }
}
export default {
  store,
  stores
}
