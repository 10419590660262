import axios from './axios.config'
// 根据角色获取关联菜单
export const getMenuByRole = (data) => axios.get(`/jaxrs/menu/get_by_role/${data.id}`)
// 获取菜单树
export const getMenuTree = (data) => axios.get(`/jaxrs/menu/tree?module=${data.module || ''}`)
// 保存角色菜单
export const saveRoleMenu = (data) => axios.post(`/jaxrs/menu/save_role_menu`,data)
// 获取菜单详情
export const getMenuDetail = (data) => axios.get(`/jaxrs/menu/${data.id}`)
// 修改菜单
export const editMenu = (data) => axios.put(`/jaxrs/menu`,data)
// 添加菜单
export const addMenu = (data) => axios.post(`/jaxrs/menu`,data)
// 删除菜单
export const deleteMenu = (data) => axios.delete(`/jaxrs/menu/${data.id}`)
