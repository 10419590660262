import { defineAsyncComponent } from 'vue'
import { getProcessDetail } from "@/axios";

const modulesFiles = import.meta.glob('@/views/**/*.vue')
const filesMap = {}
for (const key of Object.keys(modulesFiles)) {
  // console.log(key);
  let keyArray = key.split('/')
  let fileName = keyArray[keyArray.length - 1].replace('.vue', '')
  if (fileName.includes('Detail') || fileName.includes('Edit')) {
    filesMap[fileName] = defineAsyncComponent(() => modulesFiles[key]())
  }
}
export const processMixins = {
  components:{
    ...filesMap
  },
  data(){
    return {
      detailName: '',
      editName: '',
      editId: null,
      showDetailDrawer: false,
      showEditDrawer: false,
    }
  },
  methods:{
    showDetail(row) {
      this.componentStore.showProcessDetail(row)
    },
  }
}