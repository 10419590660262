<script>
import { defineComponent } from 'vue'
// import ProcessDrawerBody from '@/components/ProcessDrawer/ProcessDrawerBody.vue'
// import ProcessDrawerBodyNew from '@/components/ProcessDrawer/ProcessDrawerBodyNew.vue'
import { workCloseCheck } from '@/axios'

export default defineComponent({
  name: 'ProcessDrawer',
  emits: ['update:modelValue', 'confirm'],
  // components: { ProcessDrawerBody, ProcessDrawerBodyNew },
  data() {
    return {
      processWork: null
    }
  },
  props: {
    modelValue: {
      type: Boolean
    },
    workId: {},
    taskId: {},
    priorityWork: {},
    callback: {}
  },
  computed: {
    show: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      }
    },
    iframeUrl() {
      let url = `${location.origin}/x_desktop/app.html?app=process.Work&status={"workId":"${this.workId}","taskId":"${this.taskId}","workCompletedId":"","jobId":"","draftId":"","priorityWork":"${this.priorityWork}"}`
      // let url = `http://yuanan.dubhe-tech.com:88/x_desktop/app.html?app=process.Work&status={"workId":"${this.workId}","taskId":"${this.taskId}","workCompletedId":"","jobId":"","draftId":"","priorityWork":"${this.priorityWork}"}`
      // let url = `https://yuanan.dubhe-tech.com/x_desktop/app.html?app=process.Work&status={"workId":"${this.workId}","taskId":"${this.taskId}","workCompletedId":"","jobId":"","draftId":"","priorityWork":"${this.priorityWork}"}`
      return url
    },
    transferUrl() {
      let url = `${location.origin}${
        import.meta.env.VITE_BASEURL
      }/ProcessTransfer?url=${encodeURIComponent(this.iframeUrl)}`
      return url
    }
  },
  watch: {},
  methods: {
    init() {},
    bodyCloseCallback() {
      this.show = false
      if (this.callback) {
        this.callback()
      }
    },
    open() {
      let iframe = this.$refs.iframe
      iframe.contentWindow.close = this.closed
      // iframe.addEventListener('load',  ()=> {
      //   this.$nextTick(()=>{
      //     let styleDom = document.createElement("style");
      //     styleDom.type = "text/css";
      //     styleDom.innerHTML = '#action_close{display: none !important;}'
      //     iframe.contentWindow.document.head.appendChild(styleDom);
      //   })
      // });
    },
    closed() {
      this.show = false
      workCloseCheck({ id: this.workId })
      this.messageStore.getTaskPageCount()
    }
  }
})
</script>

<template>
  <el-drawer
    v-model="show"
    size="95%"
    class="process-drawer"
    direction="btt"
    destroy-on-close
    @closed="closed"
    @open="open"
  >
    <template #default>
      <iframe class="process-iframe" :src="iframeUrl" ref="iframe" :key="workId" scrolling="auto" frameborder="0" @close="bodyCloseCallback"></iframe>
<!--      <iframe-->
<!--        class="process-iframe"-->
<!--        :src="transferUrl"-->
<!--        ref="iframe"-->
<!--        :key="transferUrl"-->
<!--        scrolling="auto"-->
<!--        frameborder="0"-->
<!--        @close="bodyCloseCallback"-->
<!--      ></iframe>-->
      <!--      <ProcessDrawerBody :workId="workId" :taskId="taskId" :priorityWork="priorityWork" :key="workId" :closeCallback="bodyCloseCallback"></ProcessDrawerBody>-->
      <!--      <ProcessDrawerBodyNew :workId="workId" :key="workId" :closeCallback="bodyCloseCallback"></ProcessDrawerBodyNew>-->
    </template>
  </el-drawer>
</template>

<style scoped lang="scss">
.oa-drawer-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: auto;
  background: #eeeeee;
}
.process-iframe {
  height: 100%;
  width: 100%;
}
</style>