import axios from './axios.config'
// 图片上传
export const uploadImage = (data) => axios.post('/jaxrs/storage/image',data,{
  headers:{
    'Content-Type': 'multipart/form-data;'
  }
})
// 文件上传
export const uploadFile = (data) => axios.post('/jaxrs/storage/file',data,{
  headers:{
    'Content-Type': 'multipart/form-data;'
  }
})

// 根据文件名filename下载文件
export const downloadFileByFilename = (data) =>{
  if(data.filename){
    return axios.get(`/jaxrs/storage/download?filename=${data.filename}`, {
      responseType: 'blob'
    })
  }
  if(data.url){
    return axios.get(`/jaxrs/storage/download?url=${data.url}`, {
      responseType: 'blob'
    })
  }
}
