<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "EmptyLayout"
});
</script>

<template>
  <div class="empty-wrapper">
    <router-view />
  </div>
</template>

<style scoped lang="scss">
  .empty-wrapper{
    @include max-size;
    overflow: hidden;
    transform: translate(0, 0);
    position: relative;
  }
</style>