<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "FullLayout"
});
</script>

<template>
  <div class="full-wrapper">
    <img class="full-wrapper-background" :src="loginStore.loginConfig.loginBackgroundUrl" alt="">
    <img class="full-wrapper-background animate__animated animate__fadeIn" v-if="loginStore.newLoginConfig.loginBackgroundUrl" :src="loginStore.newLoginConfig.loginBackgroundUrl" alt="">
    <router-view />
  </div>
</template>

<style scoped lang="scss">
.full-wrapper{
  position: relative;
  @include max-size;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //background: url("../assets/image/login/login-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .full-wrapper-background{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
</style>