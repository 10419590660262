import { usePermissionStore } from "@/store/permission";

let permissionStore;
const checkKey = (key) => {
  if (!permissionStore) {
    permissionStore = usePermissionStore();
  }
  return permissionStore.module === key;
};

export default {
  name: "module-key",
  mounted(el, binding) {
    let key = binding.value;
    if (key) {
      let checkShow = checkKey(key);
      if (!checkShow) {
        // el.style.display = 'none'
        el.remove();
      }
    } else {
      throw new Error("缺少唯一指令");
    }
  }
};
