<script>
import { defineComponent } from 'vue'
import Cookies from 'js-cookie'

import { getLoginBind, getQywxConfig, login } from "@/axios";
import Instructions from '@/views/Login/Instructions'

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Login',
  components: { Instructions },
  computed: {},
  data() {
    return {
      loading: false,
      user: {
        username: '',
        password: ''
      },
      isQrCode: false,
      rules: {
        username: [{ required: true, message: '请输入用户名', trigger: 'change' }],
        password: [{ required: true, message: '请输入密码', trigger: 'change' }]
      },
      agreeInstructions: false,
      showInstructions: false,
      instructionsNotice:'请阅读并同意使用协议书',
      errorMessage: ''
    }
  },
  mounted() {
    // this.initQywxConfig()
    this.checkCookie()
  },
  methods: {
    checkCookie(){
      let token = Cookies.get('x-token')
      if(token && token!=='anonymous'){
        this.goHome()
      }
    },
    goHome(){
      this.$router.push({
        path: '/Home'
      })
    },
    handleLoginClick() {
      this.errorMessage = ''
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (!this.agreeInstructions) {
            this.$message.warning(this.instructionsNotice)
            return
          }
          this.loading = true
          let data = {
            credential: this.user.username,
            password: this.user.password
          }
          login(data)
            .then((res) => {
              if (res?.data?.token) {
                this.storageStore.setAgreeInstructions(this.user.username)
                this.storageStore.clearRouterHistory()
                Cookies.set('x-token', res.data.token)
                this.$message.success('登录成功')
                this.goHome()
              }
            })
            .catch((err)=>{
              if(err && err.message){
                this.errorMessage = err.message
                // this.$message.error(err.message)
              }
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    async changeLoginType() {
      // if(!this.isQrCode){
      //   await this.initQrCode()
      // }
      this.isQrCode = !this.isQrCode
    },
    // 获取企业微信配置
    initQywxConfig() {
      getQywxConfig().then((res) => {
        if (res) {
          let data = res.data
        }
      })
    },
    // 获取o2oa二维码
    async initQrCode() {
      await getLoginBind().then((res) => {
        if (res) {
          let data = res.data
          this.qrCode = data
        }
      })
    },
    usernameChange(val) {
      this.agreeInstructions = this.storageStore.hasAgreeInstructions(val)
    },
    handleInstructionsClick() {
      this.showInstructions = true
    },
    instructionsCallback(){
      this.agreeInstructions = true
    }
  }
})
</script>

<template>
  <div class="login-wrapper">
    <div class="login-slogen">
      {{loginStore.loginConfig.description}}
<!--      <img :src="getImageUrl('login/slogen.png')" />-->
    </div>
    <el-card v-loading="loading">
      <div class="change-images" v-if="loginStore.modeConfig.bindLogin">
        <template v-if="isQrCode">
          <img class="change-images-text" :src="getImageUrl('login/mima.png')" alt="" />
          <img
            class="change-images-icon"
            :src="getImageUrl('login/icon_diannao.png')"
            alt=""
            @click="changeLoginType"
          />
        </template>
        <template v-else>
          <img class="change-images-text" :src="getImageUrl('login/soma.png')" alt="" />
          <img
            class="change-images-icon"
            :src="getImageUrl('login/icon_erweima.png')"
            alt=""
            @click="changeLoginType"
          />
        </template>
      </div>
      <div class="login-panel" v-if="!isQrCode">
<!--        <img class="login-logo" :src="getImageUrl('login/logoyuanan.png')" alt="" />-->
        <div class="login-logo-wrapper">
<!--          <img :src="getImageUrl('logo_color.png')" alt="" />-->
          <img :src="loginStore.loginConfig.logoUrl" alt="" />
          <span>{{loginStore.loginConfig.name}}</span>
        </div>
        <el-form ref="form" size="large" :model="user" :rules="rules">
          <el-form-item prop="username">
            <el-input
              placeholder="用户名"
              prefix-icon="User"
              v-model="user.username"
              @input="usernameChange"
            ></el-input>
          </el-form-item>
          <el-form-item ref="password" prop="password">
            <el-input
              type="password"
              placeholder="密码"
              prefix-icon="Lock"
              v-model="user.password"
            ></el-input>
          </el-form-item>
        </el-form>
        <el-button
          class="login-button"
          size="large"
          type="primary"
          @click="handleLoginClick"
          :disabled="loading"
          >登录
        </el-button>
        <div class="login-error-message">
          {{ errorMessage }}
        </div>
      </div>
      <div class="login-panel" v-else>
        <iframe
          class="login-iframe"
          src="../x_desktop/oauth.html?oauth=企业微信&qywx=true"
        ></iframe>
        <div class="login-mask" v-if="!agreeInstructions">{{ instructionsNotice }}</div>
        <!--        <img class="login-qr-code" :src="`data:image/png;base64,${qrCode.image}`" alt="" />-->
      </div>
      <div class="info-wrapper">
        <el-checkbox v-model="agreeInstructions">
          <el-button type="text" @click="handleInstructionsClick"
            ><span class="font-black">我已阅读并同意</span>《使用协议书》</el-button
          >
        </el-checkbox>
      </div>
    </el-card>
  </div>
  <Instructions v-model="showInstructions" @agree="instructionsCallback"></Instructions>
</template>

<style scoped lang="scss">
:deep(.dubhe-card) {
  border-radius: 20px;
}
:deep(.dubhe-card__body) {
  position: relative;
  padding-top: 50px;
}

.login-wrapper {
  position: relative;
  z-index: 10;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .login-slogen {
    width: 450px;
    margin-right: 80px;
    color: #ffffff;
    font-size: 46px;
    font-weight: bold;
    letter-spacing: 4px;
    text-align: center;
    word-spacing: 30px;
    img {
      width: 100%;
    }
  }

  .login-panel {
    position: relative;
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 30px;
    .login-mask {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: rgba(255, 255, 255, 0.9);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .login-logo {
      width: 220px;
      margin: 10px auto;
    }
    .login-logo-wrapper{
      margin: 10px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 26px;
      font-weight: bold;
      letter-spacing: 1px;
      &>img{
        height: 50px;
        min-width: 50px;
        margin-right: 5px;
      }
    }

    .dubhe-form {
      width: 100%;

      .dubhe-form-item {
        margin-top: 30px;
      }
    }

    .login-button {
      width: 100%;
      height: 40px;
      margin-top: 30px;
    }

    .login-iframe {
      border-width: 0;
      width: 400px;
      height: 380px;
    }

    .login-error-message {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      color: var(--dubhe-color-danger);
    }
  }

  .login-qr-code {
    width: 280px;
    height: 280px;
  }

  .info-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
  }
}

.change-images {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;

  .change-images-text {
    height: 28px;
  }

  .change-images-icon {
    height: 50px;
    cursor: pointer;
    margin: 8px;
  }
}
</style>