const components = {}

let getModules = () => {
  return import.meta.glob('./**/*.vue')
}

const modulesFiles = getModules()

for (const key of Object.keys(modulesFiles)) {
  let file = modulesFiles[key]
  let keyArray = key.split('/')
  let keyName = keyArray[keyArray.length - 1].split('.')[0]
  components[keyName] = file
}

export default components